import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Button, Form, Container, Row, Col, Dropdown } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import img375 from '../../../images/property-details/img1.jpg'
import Slider from "react-slick";
import { PropertyLists } from "./SearchItems";
import './PropertyList.scss'
import axios from 'axios';
import ImageTransform from "../../common/ggfx-client/module/components/image-transform"
import NoImage from "../../../images/no-image.png"

const settings = {
  dots: false,
  speed: 800,
  infinite: false,
  arrows: false,
  mobileFirst: true,
  centerMode: false,
  responsive: [
    {
      breakpoint: 3020,
      settings: {
        arrows: true,
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1199,
      settings: {
        arrows: true,
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 991,
      settings: {
        arrows: false,
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 420,
      settings: {
        arrows: false,
        slidesToShow: 1,
        dots: false,
      },
    },
  ],
};
// markup
const FeaturedPropertyLet = (props) => {
  const [propItems, setPropItems] = useState(false)
  const getitems = async url => {
    try {
      const { data } = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
        }})
      setPropItems(data)
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    let officeId = props.officeId ?? "";
    let area = props.area ?? "";
    let url = process.env.GATSBY_STRAPI_SRC + "/properties?search_type=lettings&_limit=3&_sort=price:DESC&publish=true"; // TODO: base URL supposed to be from .env
    if (area) {
      url = url + "&display_address_contains=" + area;
    }
    // if (officeId) {
    //   url = url + "&office_crm_id=" + officeId;
    // }
    //console.log('aaaaaaaaaa',url)
    getitems(url)
  },[])
  return (
    <React.Fragment>
          {propItems && propItems.length > 0 &&

      <div className="property-list-block landing-featured-properties">
        
          <Container>
          {propItems && propItems.length > 0 &&
            <Row>
              <Col md="12">
                <div className="block-title d-flex">
                  {props.officetitle?
                  <h2>{props.officetitle}</h2>
                  :
                  <h2>Featured Properties{props.title}</h2>
                  }
                  <div className="ms-auto d-flex align-items-center">
                    <Link to={`/properties-to-rent/${props.area?props.area.toLowerCase().replace(/ /g,"-"):"essex-and-kent"}/`} className="link-text d-none d-lg-inline-block">
                      View all Properties
                    </Link>
                    <Link to={`/properties-to-rent/${props.area?props.area.toLowerCase().replace(/ /g,"-"):"essex-and-kent"}/`}>
                      <button type="button" className="btn-circle">
                        <i className="icon-arrow"></i>
                      </button>
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
            }
            <Slider className="property-slider" {...settings}>
              {/* <div className="property-slider"> */}
              {propItems && propItems.map((item, key) => {
                
              let processedImages = JSON.stringify({});
              if (item?.imagetransforms?.images_Transforms) {
                  processedImages = item.imagetransforms.images_Transforms;
              }       
              var checkptype = item.search_type ==="sales"?'/property-for-sale/' :'/property-to-rent/'
              return <>
                <div className="search-item-box">
                  <div className="search-property-img img-zoom">
                    <Link to={`${checkptype}${item.slug}-${item.id}/`} className="img-zoom">
                    {item.images[0] ?
                    <ImageTransform imagesources={item.images[0].url} renderer="srcSet" imagename="property.images.similarpropertytile"attr={{ alt: item.display_address+ ' -  Balgores' }}
                        imagetransformresult={processedImages} id={item.id} testparam={true} />
                    :
                    <img src={NoImage} alt= {item.display_address + ' - Balgores'} />
                    }
                    </Link>
                    {item.status &&
                    <span className="property-tag">
                      {item.status}
                    </span>
                    }
                  </div>
                  <div className="search-property-details">
                    <button type="button" className="like-btn d-none">
                      <i className="icon-heart-outline"></i>
                    </button>
                    <h2>
                      <Link to={`${checkptype}${item.slug}-${item.id}/`}>{item.display_address} </Link>
                    </h2>
                    <span className="search-property-text">
                      {item.title}
                    </span>
                    <span className="property-price">
                    {'£' + item.price.toLocaleString()}<span>{item.search_type === "lettings"?"pcm":item.price_qualifier}</span>
                    </span>
                    <div className="btn-wrapper">
                      <Link class="btn btn-primary" to={`/book-a-viewing?pid=${item.id}`}>Enquiry Now</Link>
                      <Link class="btn btn-property" to={`${checkptype}${item.slug}-${item.id}/`}>View Property</Link>
                    </div>
                    

                  </div>
                </div>
                </>
              })}

              {/* </div> */}
            </Slider>
          </Container>
        
      </div>
}
    </React.Fragment>
  )
}
export default FeaturedPropertyLet